@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200..900&family=JetBrains+Mono:wght@700&family=Roboto:wght@300&display=swap');

.hero {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 40vh;
  width: 100vw;
}

.hero-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 900px;
  padding: 8px;
}

.title {
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;
  font-variation-settings: "wdth" 100;
  font-size: 3rem;
  text-align: center;
}

header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  font-optical-sizing: auto;
}

.feed-card {
  transition: all 0.2s ease-in-out;
}

.feed-card:hover {
  transform: scale(1.01);
  box-shadow: 0px 2px 0px #E6E4D9;
}

.logo {
  font-weight: 900;
  font-style: normal;
  font-variation-settings: "wdth" 100;
  font-size: 1.5rem;
}